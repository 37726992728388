'use client'
import DonationFormRequiredField from '@/donationPages/_dependencies/components/DonationFormRequiredField'
import FormInput from '@/form/FormInput'
import { LayoutStack } from '@betterplace/design-system/client'
import { useTranslations } from 'next-intl'

function HolderFields() {
  const t = useTranslations()
  return (
    <LayoutStack space="400">
      <DonationFormRequiredField name="first_name" title={t('nextjs.donate.attributes.first_name')} as={FormInput} />
      <DonationFormRequiredField name="last_name" title={t('nextjs.donate.attributes.last_name')} as={FormInput} />
      <DonationFormRequiredField name="email" type="email" title={t('nextjs.donate.attributes.email')} as={FormInput} />
    </LayoutStack>
  )
}

export default HolderFields
