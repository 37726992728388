import styles from './ProhibitedNotice.module.css'
import { Button, Heading, LayoutStack } from '@betterplace/design-system/server'
import { useTranslations } from 'next-intl'

function ProhibitedNotice({ backHref }: { backHref?: string }) {
  const t = useTranslations('nextjs.donate')
  return (
    <div className={styles.outerContainer}>
      <LayoutStack space="300" className={styles.container}>
        <Heading level="h200">{t('prohibited_headline')}</Heading>
        <p>{t('prohibited_notice')}</p>
        {backHref && (
          <Button href={backHref} as="a">
            {t('prohibited_call_to_action')}
          </Button>
        )}
      </LayoutStack>
    </div>
  )
}

export default ProhibitedNotice
