'use client'

import DonationFormField from '@/donationPages/_dependencies/components/DonationFormField'
import I18nHtml from '@/components/I18nHtml'
import Modal from '@/donationPages/_dependencies/components/Modal'
import TextLink from '@/components/TextLink'
import styles from './PlatformNewsletterCheckbox.module.css'
import { Button, Checkbox } from '@betterplace/design-system/client'
import { NewsletterCheckboxProps } from './types'
import { forwardRef, useCallback, useState } from 'react'
import { postMessageScrollTop } from '@/helpers/utils'
import { useDonationFormConfig } from '@/donationPages/_dependencies/helpers'
import { useTranslations } from 'next-intl'

const NewsletterCheckbox = forwardRef<HTMLInputElement, NewsletterCheckboxProps>(function NewsletterCheckBox(
  { openModal, ...props }: NewsletterCheckboxProps,
  ref
) {
  const t = useTranslations('nextjs.core.newsletter_consent.platform')
  return (
    <div className={styles.checkboxWrapper}>
      <Checkbox {...props} ref={ref} id="subscribeToPlatformNewsletter">
        <div>
          {t('label_prefix.donation_form')}{' '}
          <TextLink href="javascript:void(0)" onClick={openModal}>
            {t('consent_modal_opener_label')}
          </TextLink>{' '}
          {t('label_suffix')}
        </div>
      </Checkbox>
    </div>
  )
})

function PlatformNewsletterCheckbox() {
  const t = useTranslations('nextjs.core')
  const { showPlatformSubscription } = useDonationFormConfig()
  const [showModal, setShowModal] = useState(false)

  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])
  const openModal = useCallback((event?: React.MouseEvent | React.KeyboardEvent) => {
    // Prevent the checkbox to (un)tick.
    if (event) event.preventDefault()

    setShowModal(true)
  }, [])

  if (!showPlatformSubscription) return null

  return (
    <>
      <DonationFormField name="newsletter" as={NewsletterCheckbox} openModal={openModal} />
      <Modal
        id="betterplace-newsletter-subscription-legal-modal"
        onClose={hideModal}
        onOpen={postMessageScrollTop}
        isOpen={showModal}
        title={t('newsletter_consent.platform.legal_modal.heading')}
        customActions={
          <Button kind="primary" size="small" onClick={hideModal} type="button" className={styles.closeModalButton}>
            {t('close')}
          </Button>
        }
        closeText={t('close')}
      >
        <I18nHtml i18nKey="nextjs.core.newsletter_consent.platform.legal_modal.content_html" />
      </Modal>
    </>
  )
}

export default PlatformNewsletterCheckbox
