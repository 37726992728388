'use client'

import I18nHtml from '@/components/I18nHtml'
import Modal from '../../Modal'
import TextLink from '@/components/TextLink'
import classNames from 'classnames'
import styles from './MonthlyMatchingBanner.module.css'
import { DonationIntervals } from '@betterplace/api-graphql-types/enums'
import { NextIntlKeys } from '@/i18n/types'
import { useCallback, useMemo, useState } from 'react'
import { useDonationFormConfig, useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function MonthlyMatchingBanner({ className }: { className?: string }) {
  const t = useTranslations()
  const [showDetails, setShowDetails] = useState(false)
  const { showMonthlyMatching, channel } = useDonationFormConfig()
  const [interval] = useDonationFormValues(['interval'])
  const handleClick = useCallback(() => {
    setShowDetails(true)
    ;(document.activeElement as HTMLInputElement | null)?.blur()
  }, [])

  const bannerTextKey = useMemo(() => {
    return (
      interval === DonationIntervals.Monthly
        ? 'nextjs.donate.monthly_matching_banner_endorsement_html'
        : 'nextjs.donate.monthly_matching_banner_teaser_html'
    ) as NextIntlKeys
  }, [interval])
  const hideDetails = useCallback(() => setShowDetails(false), [])
  if (!showMonthlyMatching || channel !== 'platform') return null

  return (
    <>
      <div className={classNames(styles.bannerContainer, className)}>
        <p className={styles.learnMoreText}>
          <I18nHtml i18nKey={bannerTextKey} />{' '}
          <TextLink href="javascript:void(0)" className={styles.gentleLink} onClick={handleClick}>
            {t('nextjs.donate.learn_more')}
          </TextLink>
        </p>
      </div>
      <Modal
        id="matching-fund"
        isOpen={showDetails}
        onClose={hideDetails}
        title={t('nextjs.donate.monthly_matching_banner_modal_header')}
        closeText={t('nextjs.core.close')}
        hideActions
      >
        <I18nHtml
          as="span"
          i18nKey="nextjs.donate.monthly_matching_banner_modal_content_html"
          options={{
            href: t('nextjs.donate.monthly_matching_banner_modal_content_url'),
            target: '_blank',
          }}
        />
      </Modal>
    </>
  )
}

export default MonthlyMatchingBanner
