'use client'

import AddressFieldToggle from '@/donationPages/_dependencies/components/formParts/AddressFieldToggle'
import AddressFields from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/AddressFields'
import AmountAndIntervalSelection from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/AmountAndIntervalSelection'
import CodonationBox from './CodonationBox'
import CompanyFields from '@/donationPages/_dependencies/components/formParts/CompanyFields'
import Debug from '@/donationPages/_dependencies/components/formParts/Debug'
import DonationFormCaptcha from '@/donationPages/_dependencies/components/formParts/DonationFormCaptcha'
import GeneralError from '@/donationPages/_dependencies/components/formParts/GeneralError'
import HeroImage from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/HeroImage'
import HolderFields from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/HolderFields'
import HolderHeadline from '@/donationPages/_dependencies/components/formParts/HolderHeadline'
import HolderTitle from '@/donationPages/_dependencies/components/formParts/HolderTitle'
import MatchingEventNotice from '@/donationPages/_dependencies/components/formParts/MatchingEventNotice'
import MessageField from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/MessageField'
import PaymentMethodError from '@/donationPages/_dependencies/components/formParts/PaymentMethodError'
import PaymentMethodSelection from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/PaymentMethodSelection'
import PlatformFormDonationAmountSum from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/PlatformFormDonationAmountSum'
import PlatformNewsletterCheckbox from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/PlatformNewsletterCheckbox'
import ProhibitedNotice from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/ProhibitedNotice'
import StripeCCFields from '@/donationPages/_dependencies/components/formParts/StripeFields/StripeCCFields'
import StripeSEPADebitFields from '@/donationPages/_dependencies/components/formParts/StripeFields/StripeSEPADebitFields'
import SubmitButton from '@/donationPages/_dependencies/components/formParts/SubmitButton'
import Terms from '@/donationPages/_dependencies/components/formParts/Terms'
import TrustLogos from '@/donationPages/_dependencies/components/formParts/TrustLogos'
import VotingQuestion from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/VotingQuestion'
import YoutubeFields from '@/donationForm/_dependencies/components/PlatformFormVariantSelector/PlatformForm/YoutubeFields'
import styles from './PlatformForm.module.css'
import { LayoutStack } from '@betterplace/design-system/client'
import { Messages } from '@/components/Messages'
import { useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import type { DonationFormProps } from '@/donationForm/types'

function PlatformForm({ config, initialFormValues, initialFFs, ...props }: DonationFormProps) {
  const { donationsProhibited, receiverName, receiverProfilePicture, receiverUrl, matchingEvent, showCodonation } =
    config
  const [companyDonation] = useDonationFormValues(['_company_donation'])
  if (donationsProhibited) {
    return (
      <>
        <HeroImage
          receiverName={receiverName}
          receiverProfilePicture={receiverProfilePicture}
          receiverUrl={receiverUrl}
          linked
        />
        <ProhibitedNotice backHref={receiverUrl} />
      </>
    )
  }

  return (
    <>
      <HeroImage
        receiverName={receiverName}
        receiverProfilePicture={receiverProfilePicture}
        receiverUrl={receiverUrl}
        linked
      />
      {matchingEvent && (
        <div className={styles.donationFormNotices}>
          <MatchingEventNotice matchingEvent={matchingEvent} />
        </div>
      )}

      <form {...props}>
        <div className={styles.donationFormContent}>
          <Messages />
          <GeneralError />
          <AmountAndIntervalSelection />
          <VotingQuestion />
          <div className={styles.formFieldsContainer}>
            <LayoutStack space="50" className={styles.blockWrapper}>
              <PaymentMethodSelection />
              <StripeCCFields />
              <StripeSEPADebitFields />
              <PaymentMethodError />
            </LayoutStack>

            <LayoutStack space="400" className={styles.blockWrapper}>
              <HolderHeadline />
              <HolderFields />
              {!companyDonation && (
                <>
                  <MessageField />
                  <YoutubeFields />
                </>
              )}
            </LayoutStack>

            {companyDonation && (
              <LayoutStack className={styles.blockWrapper} space="400">
                <HolderTitle />
                <LayoutStack space="400">
                  <CompanyFields />
                  <AddressFields required />
                  <MessageField />
                  <YoutubeFields />
                </LayoutStack>
              </LayoutStack>
            )}

            {!companyDonation && (
              <LayoutStack className={styles.blockWrapper} space="200">
                <AddressFieldToggle>
                  <AddressFields required />
                </AddressFieldToggle>
              </LayoutStack>
            )}

            <CodonationBox />
            {showCodonation && <PlatformFormDonationAmountSum />}
            <PlatformNewsletterCheckbox />
            <SubmitButton />
            <Terms />
            <TrustLogos />
            <DonationFormCaptcha />
          </div>
        </div>
      </form>
      <Debug initialFFs={initialFFs} initialFormValues={initialFormValues} />
    </>
  )
}

export default PlatformForm
