import styles from './NavigationArrow.module.css'
import { IconButton, useAttributeObserver } from '@betterplace/design-system/client'
import { type RefObject, useRef } from 'react'
import { useTranslations } from 'next-intl'

function useWatchDisabledClassToggle(ref: RefObject<HTMLElement>, disabledClass?: string) {
  const { newValue } = useAttributeObserver(ref, 'class')
  return !!disabledClass && newValue?.includes(disabledClass)
}

function NavigationArrow({
  direction,
  id,
  title,
  disabledClass,
}: {
  direction?: 'left' | 'right'
  id?: string
  size?: string
  title?: string
  disabledClass?: string
}) {
  const ref = useRef<HTMLButtonElement>(null)
  const margin = direction === 'left' ? styles.leftArrow : styles.rightArrow
  const icon = direction === 'left' ? 'arrowLeft' : 'arrowRight'
  const t = useTranslations('nextjs.core.state_labels')
  const disabled = useWatchDisabledClassToggle(ref, disabledClass)
  return (
    <IconButton
      id={id}
      ref={ref}
      iconName={icon}
      kind="plain"
      className={margin}
      onClick={(e) => e.preventDefault()}
      aria-label={title}
      aria-disabled={disabled}
      disabledReason={t('disabled')}
    />
  )
}

export default NavigationArrow
