'use client'

import DirectDepositInfo from '@/donationPages/_dependencies/components/formParts/DirectDepositInfo'
import DonationFormField from '@/donationPages/_dependencies/components/DonationFormField'
import FiscalYearChangeNotice from '@/donationPages/_dependencies/components/formParts/FiscalYearChangeNotice'
import styles from './PaymentMethodSelection.module.css'
import useTrackPaymentMethodChange from './useTrackPaymentMethodChange'
import { DonationIntervals, PaymentMethods } from '@betterplace/api-graphql-types/enums'
import {
  Fieldset,
  FieldsetProps,
  Heading,
  Icon,
  type IconName,
  LoadingSkeleton,
  RadioButton,
  Tooltip,
} from '@betterplace/design-system/client'
import { toLowerCase } from '@betterplace/utils'
import { useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import { useEffect, useState } from 'react'
import {
  usePaymentMethodsToRender,
  useSelectDefaultPaymentMethod,
} from '@/donationPages/_dependencies/NativePaymentMethods'
import { useTranslations } from 'next-intl'

const iconsToPaymentMethodMap = {
  [PaymentMethods.DirectDeposit]: 'wireTransfer' as IconName,
  [PaymentMethods.Paypal]: 'payPal' as IconName,
  [PaymentMethods.StripeApplePay]: 'applePay' as IconName,
  [PaymentMethods.StripeGooglePay]: 'googlePay' as IconName,
  [PaymentMethods.StripeSepaDebit]: 'sepa' as IconName,
  [PaymentMethods.StripeCc]: 'creditCard' as IconName,
  [PaymentMethods.DummyPayment]: 'creditCard' as IconName,
}

function PaymentMethodSelection({
  className,
  layout = 'vertical',
}: {
  className?: string
  layout?: FieldsetProps['layout']
}) {
  const t = useTranslations()

  const { nativePaymentsLoading, paymentMethodsToRender, recurringPaymentMethodsToRender } = usePaymentMethodsToRender()

  const [interval, paymentMethod] = useDonationFormValues(['interval', 'payment_method'])

  const isRecurring = interval !== DonationIntervals.Single
  const [isTwitterBrowser, setIsTwitterBrowser] = useState(false)

  useSelectDefaultPaymentMethod()

  useEffect(() => {
    typeof navigator !== 'undefined' && setIsTwitterBrowser(/Twitter/i.test(navigator.userAgent))
  }, [])

  useTrackPaymentMethodChange()

  return (
    <>
      <Fieldset
        legend={
          <Heading level="h200" className={styles.headingMargin}>
            {t('nextjs.donate.payment_method.headline')}
          </Heading>
        }
        layout={layout}
        layoutProps={{ className }}
        className={styles.fieldset}
      >
        {nativePaymentsLoading && (
          <div key="fake" className={styles.skeletonContainer}>
            <LoadingSkeleton width="100%" height="2.5rem" key="fake" />
          </div>
        )}
        {paymentMethodsToRender.map((name) => {
          const isBrowserUnsupported = name === PaymentMethods.Paypal && isTwitterBrowser
          const isRecurringUnsupported = isRecurring && !recurringPaymentMethodsToRender.includes(name)

          const disabled = isBrowserUnsupported || isRecurringUnsupported

          const tooltipContent = isBrowserUnsupported
            ? t('nextjs.donate.errors.paypal_unsupported_browser')
            : isRecurringUnsupported
              ? t('nextjs.donate.recurring_disabled_tooltip')
              : null

          return (
            <div key={name}>
              <Tooltip content={tooltipContent} inactive={!disabled}>
                <DonationFormField
                  name="payment_method"
                  type="radio"
                  as={RadioButton}
                  value={name}
                  disabled={disabled || nativePaymentsLoading}
                  classNames={{ container: styles.radio, label: styles.radioLabel }}
                >
                  <div className={styles.labelContent}>
                    {t(`nextjs.donate.payment_method.labels.${toLowerCase(name)}`)}
                    {<Icon name={iconsToPaymentMethodMap[name]} className={styles.paymentMethodIcon} />}
                  </div>
                </DonationFormField>
              </Tooltip>
            </div>
          )
        })}
      </Fieldset>
      <DirectDepositInfo paymentMethod={paymentMethod} />
      <FiscalYearChangeNotice paymentMethod={paymentMethod} />
    </>
  )
}

export default PaymentMethodSelection
