'use client'

import ControlVariant from '@/donationPages/_dependencies/components/formParts/PlatformForm'
// import TestVariant from './PlatformForm'
// import useFeatureFlag from '@/analytics/featureFlags/useFeatureFlag'
import { DonationFormProps } from '@/donationForm/types'

function PlatformFormVariantSelector(props: DonationFormProps) {
  // const hasExplanationFeatureFlag = useFeatureFlag('betterplace-explanation', 'test')
  // if (hasExplanationFeatureFlag) return <TestVariant {...props} />
  return <ControlVariant {...props} />
}

export default PlatformFormVariantSelector
