import AmountSelection from './AmountSelection'
import IntervalSelection from './IntervalSelection'
import { LayoutStack } from '@betterplace/design-system/client'

function AmountAndIntervalSelection() {
  return (
    <LayoutStack space="500">
      <IntervalSelection />
      <AmountSelection />
    </LayoutStack>
  )
}

export default AmountAndIntervalSelection
