import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import { useDonationFormConfig } from '@/donationPages/_dependencies/DonationFormConfigContext'
import { useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import { useEffect, useRef, useState } from 'react'
import type { PaymentMethods } from '@betterplace/api-graphql-types'

function useTrackPaymentMethodChange() {
  const { receiverId, receiverType, channel, showCodonation } = useDonationFormConfig()
  const [paymentMethod, defaultPaymentMethod] = useDonationFormValues(['payment_method', '_default_payment_method'])

  const previousPaymentMethod = useRef<PaymentMethods>(paymentMethod)

  const [initialChange, setInitialChange] = useState(true)

  useEffect(() => {
    setInitialChange(true)
  }, [defaultPaymentMethod])

  useEffect(() => {
    if (initialChange && paymentMethod === defaultPaymentMethod) return
    if (paymentMethod !== defaultPaymentMethod) {
      setInitialChange(false)
    }

    analytics.paymentMethodChanged({
      id: receiverId,
      receiver_type: receiverType,
      donation_form_type: getDonationFormType({
        receiverId,
        receiverType,
        channel,
        showCodonation,
      }),
      previous_payment_method: previousPaymentMethod.current,
      new_payment_method: paymentMethod,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  useEffect(() => {
    previousPaymentMethod.current = paymentMethod
  }, [paymentMethod])
}

export default useTrackPaymentMethodChange
